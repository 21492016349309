'use client';

import color from '@haaretz/l-color.macro';
import { articleHeaderId } from '@haaretz/s-consts';
import Modal from '@haaretz/s-modal';
import React from 'react';
import s9 from 'style9';

import { useRainbowSlotActions } from '../../../RainbowSlot';

const c = s9.create({
  modalWrapper: {
    border: 'none',
    width: '100%',
    maxWidth: '100%',
    height: 'calc(100% - var(--mrgn-tp-rnbw))',
    position: 'fixed',
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(to bottom, ${color('neutral100')} 15%, ${color(
      'primary200'
    )})` as string,
    translate: '0 0',
    transitionDuration: '0.25s',
    transitionProperty: 'translate',
    transitionTimingFunction: 'ease-in-out',
    '::backdrop': {
      animationDuration: '0.25s',
      transitionTimingFunction: 'ease-in-out',
    },
  },
  closing: {
    translate: '0 100%',
    '::backdrop': {
      animationName: s9.keyframes({
        '0%': { opacity: 1, visibility: 'visible' },
        '100%': { opacity: 0, visibility: 'hidden' },
      }),
    },
  },
});

const defaultMarginTop = 146;
const offset = 5;

function getModalPosition() {
  const header = document.getElementById(articleHeaderId);

  if (header) {
    const rect = header.getBoundingClientRect();

    const lineHeight = getComputedStyle(header).getPropertyValue('line-height');
    const line = parseInt(lineHeight);
    const height = getComputedStyle(header).getPropertyValue('height');
    const halfLine = line / 2;
    const margin = rect.top + halfLine + offset;

    // if there is more than one line in the header
    if (parseInt(height) > line) {
      return margin + line;
    } else {
      return margin;
    }
  }

  return defaultMarginTop;
}

export default function SuperOverlayPaywallWrapper({ children }: React.PropsWithChildren<unknown>) {
  const { closing } = useRainbowSlotActions();
  const [marginTop, setMarginTop] = React.useState(defaultMarginTop);

  React.useLayoutEffect(() => {
    const modalPosition = getModalPosition();
    setMarginTop(modalPosition);
  }, []);

  React.useEffect(() => {
    const repositionModal = () => {
      const modalPosition = getModalPosition();
      setMarginTop(modalPosition);
    };
    window.addEventListener('resize', repositionModal);

    return () => {
      window.removeEventListener('resize', repositionModal);
    };
  }, []);

  return (
    <Modal
      labelledBy="super-overlay-paywall"
      isOpen
      styleExtend={[c.modalWrapper, closing && c.closing]}
      forbidClosing
      inlineStyle={{ '--mrgn-tp-rnbw': `${marginTop}px` }}
    >
      {children}
    </Modal>
  );
}
