'use client';

import useBi from '@haaretz/s-use-bi';
import * as React from 'react';

import type { BiFunction } from '@haaretz/s-use-bi';

const BI_DATA_ATTR = 'data-bidata';

let sendBi: BiFunction;

function isBiDataLink(element: Event['target']): element is HTMLAnchorElement {
  return !!(
    element &&
    element instanceof HTMLElement &&
    element.tagName.toLocaleLowerCase() === 'a' &&
    element.hasAttribute(BI_DATA_ATTR)
  );
}

function biDataLinkClickHandler(evt: Event) {
  const { target } = evt;

  if (isBiDataLink(target)) {
    const biDataAttr = target.getAttribute(BI_DATA_ATTR);

    try {
      const biData = biDataAttr && JSON.parse(decodeURIComponent(biDataAttr));
      sendBi && sendBi(biData);
    } catch (e) {
      console.error(`unable to execute bi-data action request for bi-data: ${biDataAttr}`, e);
    }
  }
}

/**
 * Sends Bi-action requests for static links (a tags) that rendered on server-side
 * @returns null
 */
export default function BiDataLinks() {
  sendBi = useBi('action');

  React.useEffect(() => {
    document.body.addEventListener('click', biDataLinkClickHandler);
  }, []);

  return null;
}
