/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Icon from '@haaretz/s-icon';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import type { IconProps } from '@haaretz/s-icon';
import type { StyleExtend, InlineStyles } from '@haaretz/s-types';

const padding = 0.05;
const duration = '1.5s';
const offset = 175;

// `c` is short for `classNames`
const c = s9.create({
  wrapper: {
    color: `var(--loader-indicator-color, ${color('primary1000')})`,
    display: 'inline-flex',
    paddingBottom: `${padding}em`,
    paddingInlineEnd: `${padding}em`,
    paddingInlineStart: `${padding}em`,
    paddingTop: `${padding}em`,
    position: 'relative',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  placeholder: {
    width: '1em',
    height: '1em',
  },
  spinnerSvg: {
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    animationDuration: duration,
    animationIterationCount: 'infinite',
    animationName: s9.keyframes({
      '0%': { transform: 'rotate(0deg)' },
      to: { transform: 'rotate(270deg)' },
    }),
    animationTimingFunction: 'linear',
  },
  spinnerPath: {
    animationDuration: duration,
    animationIterationCount: 'infinite',
    animationName: s9.keyframes({
      '0%': { strokeDashoffset: offset },
      '50%': {
        strokeDashoffset: offset / 4,
        transform: 'rotate(135deg)',
      },
      '100%': {
        strokeDashoffset: offset,
        transform: 'rotate(450deg)',
      },
    }),
    animationTimingFunction: 'ease-in-out',
    fill: 'none',
    stroke: 'currentColor',
    strokeDasharray: offset,
    strokeDashoffset: 0,
    strokeLinecap: 'round',
    strokeWidth: 5,
    transformOrigin: 'center',
  },
  icon: {
    // This makes the icon fit inside the spinner based on
    // the padding of the wrapper while keeping the whole thing
    // at `1em`.
    // prettier-ignore
    fontSize: `${1 - (padding * 2)}em`,
  },
});

export interface LogoLoadingIndicatorProps {
  /**
   * Display the loading indicator without the logo
   */
  hideLogo?: true;
  /** The notification read by screen readers to notify the user
   * that something is happening, e.g., "Loading more articles".
   */
  busyNotice?: string | null;
  /**
   * CSS declarations to be set as inline `style` on the
   * html element.
   *
   * By setting values of CSS Custom Properties based on
   * props or state in the consuming component (where
   * the value of `inlineStyle` is passed), `inlineStyle`
   * can be used as an API contract for setting dynamic
   * values to styles created with `style9.create()`:
   *
   * @example
   * ```ts
   * import s9 from 'style9';
   * const { styleExtend, } = s9.create({
   *   styleExtend: {
   *     color: 'var(--color-based-on-prop)',
   *   },
   * });
   *
   * function MyLogoLoadingIndicator(props) {
   *   const inlineStyle = {
   *     '--color-based-on-prop': props.color,
   *   },
   *
   *   return (
   *    <LogoLoadingIndicator
   *      styleExtend={[ styleExtend, ]}
   *      inlineStyle={inlineStyle}
   *    />
   *   );
   * }
   * ```
   */
  inlineStyle?: InlineStyles;
  /**
   * An array of `Style`s created by `style9.create()`.
   * WARNING: **_do not_** pass simple CSS-in-JS object.
   * The items in the array must be created with Style9's
   * `create` function.
   * The array can also hold falsy values to assist with
   * conditional inclusion of `Style`s:
   *
   * @example
   * ```ts
   * const { foo, bar, } = s9.create({ foo: { ... }, bar: { ... }, });
   * <LogoLoadingIndicator styleExtend={[ someCondition && foo, bar, ]} />
   * ```
   */
  styleExtend?: StyleExtend;
}

const iconName = fork<IconProps['icon']>({ default: 'alef', tm: 'm' });

const LogoLoadingIndicator = React.forwardRef(function LogoLoadingIndicator(
  { hideLogo, inlineStyle, styleExtend = [], busyNotice }: LogoLoadingIndicatorProps,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <div
      className={s9(c.wrapper, ...styleExtend)}
      style={inlineStyle}
      data-testid="logo-loading-indicator"
      ref={ref}
    >
      <VisuallyHidden>
        {busyNotice ||
          fork({
            default: 'טוען...',
            hdc: 'Loading...',
          })}
      </VisuallyHidden>
      <svg aria-hidden viewBox="0 0 66 66" className={s9(c.spinnerSvg)}>
        <circle className={s9(c.spinnerPath)} cx="33" cy="33" r="30" />
      </svg>
      {hideLogo ? (
        <div className={s9(c.placeholder)} />
      ) : (
        <Icon icon={iconName} styleExtend={[c.icon]} />
      )}
    </div>
  );
});

export default LogoLoadingIndicator;
