import { useMemo } from 'react';

import type { BiDataOverrides, RainbowCommonBiData } from '@haaretz/s-data-structure-types';

export default function useRainbowCommonBiData({
  featureName,
  featureType,
  featureTheme,
  campaignName,
  campaignDetails,
  metaData,
  testGroup,
}: RainbowCommonBiData) {
  const commonBiData = useMemo(() => {
    const additionalInfo = metaData?.entries?.reduce(
      (acc, entry) => ({
        ...acc,
        [entry.key]: entry.value,
      }),
      {}
    );

    const [abTestName, abTestGroup, abTestGroupLabel] = testGroup ? testGroup.split(':') : [];

    return {
      feature: featureName,
      feature_type: featureType,
      feature_theme: featureTheme,
      campaign_name: campaignName,
      campaign_details: campaignDetails,
      additional_info: additionalInfo,
      ab_test_name: abTestName,
      ab_test_group: abTestGroup as BiDataOverrides['ab_test_group'],
      ab_test_group_label: abTestGroupLabel,
    };
  }, [
    campaignDetails,
    campaignName,
    featureName,
    featureType,
    featureTheme,
    metaData?.entries,
    testGroup,
  ]);

  return commonBiData;
}
