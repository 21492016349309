import fork from '@haaretz/l-fork.macro';

export type LogicalSide = 'start' | 'end';

export interface OnboardingRelativePosition {
  x: number;
  y?: never;
  side: LogicalSide;
  maxWidth: number;
}

export default function getRelativePosition(
  reference: Element,
  floating: Element
): OnboardingRelativePosition {
  let distance = 0;
  let floatingSide: LogicalSide = 'end';
  let maxWidth = 0;

  const referenceRect = reference.getBoundingClientRect();
  const floatingRect = floating.getBoundingClientRect();
  const parentRect = floating.parentElement?.getBoundingClientRect();

  maxWidth = floatingRect.width;

  const isReferenceLeft =
    referenceRect.left + referenceRect.width / 2 < floatingRect.left + floatingRect.width / 2;

  if (isReferenceLeft) {
    distance = referenceRect.left + referenceRect.width / 2 - floatingRect.left;
    floatingSide = fork({ default: 'end', hdc: 'start' });
  } else {
    distance = referenceRect.left + referenceRect.width / 2 - floatingRect.right;
    floatingSide = fork({ default: 'start', hdc: 'end' });
  }

  const newFloatingLeft = floatingRect.left + distance;

  // is overflow on left side
  if (parentRect && newFloatingLeft - parentRect.left < 0) {
    maxWidth = maxWidth - Math.abs(newFloatingLeft - parentRect.left);
  }
  // is overflow on right side
  else if (parentRect && newFloatingLeft + floatingRect.width > parentRect.right) {
    maxWidth = maxWidth - Math.abs(newFloatingLeft + floatingRect.width - parentRect.right);
  }

  return { x: distance, side: floatingSide, maxWidth };
}
