import fork from '@haaretz/l-fork.macro';

import type { LogicalSide } from './getRelativePosition';

export interface OnboardingAbsolutePosition {
  x: number;
  y: number;
  side: LogicalSide;
  maxWidth?: never;
}

export default function getAbsolutePosition(
  reference: Element,
  floating: Element
): OnboardingAbsolutePosition {
  let x = 0;
  let y = 0;
  let floatingSide: LogicalSide = 'end';

  const referenceRect = reference.getBoundingClientRect();
  const floatingRect = floating.getBoundingClientRect();

  const isReferenceLeft =
    referenceRect.left + referenceRect.width / 2 < floatingRect.left + floatingRect.width / 2;

  if (isReferenceLeft) {
    x = referenceRect.left + referenceRect.width / 2 - floatingRect.left;
    floatingSide = fork({ default: 'end', hdc: 'start' });
  } else {
    x = referenceRect.left + referenceRect.width / 2 - floatingRect.right;
    floatingSide = fork({ default: 'start', hdc: 'end' });
  }

  y = referenceRect.bottom - floatingRect.top + 8;

  return { x, y, side: floatingSide };
}
