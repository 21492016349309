import breakUrl from '../breakUrl';

function isArticleURL(url: string) {
  if (!url) return false;

  const { pathname } = breakUrl(url);

  const regex =
    /(?:ty-article).*(?:[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})$/;
  const result = regex.test(pathname);

  return result;
}

export default isArticleURL;
