import * as React from 'react';

import { RainbowToolsContext } from '@haaretz/s-rainbow/RainbowDataProvider';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
/**
 * Ask to fetch rainbow-tool for given slot-id.
 * @param slotId - id of slot to look for tools for it
 * @param timeout - seconds to wait for response from server
 * @returns rainbow-tool associated with slot-id
 */
export function useRainbowSlot(slotId: string, timeout?: number) {
  const { tools, defaultTools, slots } = React.useContext(RainbowToolsContext);
  const [tool, setTool] = React.useState<RainbowToolFragment>();
  const defaultTool = React.useMemo(
    () => defaultTools.find(isItemOfSlot(slotId)),
    [defaultTools, slotId]
  );
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  let toolFromServer = null;

  /**
   * If the slots set doesnt contains the slot-id,
   * that means this is the first time the slot is rendered.
   */
  if (!slots.has(slotId)) {
    slots.add(slotId);

    if (timeout && timeout > 0 && defaultTool) {
      timeoutRef.current = setTimeout(() => {
        setTool(defaultTool);
      }, timeout * 1000);
    }
  }

  if (!tool) {
    toolFromServer = tools.find(isItemOfSlot(slotId));
  }

  if (!tool && toolFromServer) {
    setTool(toolFromServer);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  return tool;
}

/**
 *
 * @param slotId - Rainbowtool predicate creator for given slot
 * @returns A predicate to test if a given item belongs to a slot
 */
function isItemOfSlot(slotId: string) {
  return (item: RainbowToolFragment) => item.slotId === slotId;
}
