'use client';
import useImpressionObserver from '@haaretz/s-use-impression-observer';
import { useCallback, useRef } from 'react';
import s9 from 'style9';

import useRainbowCommonBiData from '../../utils/useRainbowCommonBiData';

import type { RainbowCommonBiData } from '@haaretz/s-data-structure-types';

const c = s9.create({
  pixel: {
    height: '1px',
    width: '1px',
    opacity: 0,
    position: 'absolute',
    pointerEvents: 'none',
    userSelect: 'none',
  },
});
export default function RainbowImpression(props: RainbowCommonBiData) {
  const elementRef = useRef<HTMLDivElement | null>(null);
  const refCallback = useCallback((el: HTMLDivElement) => {
    elementRef.current = el;
  }, []);
  const commonBiData = useRainbowCommonBiData(props);

  useImpressionObserver({
    elementRef,
    biData: commonBiData,
  });

  return <div className={s9(c.pixel)} aria-hidden="true" ref={refCallback} />;
}
