'use client';

import color from '@haaretz/l-color.macro';
import space from '@haaretz/l-space.macro';
import typesetter from '@haaretz/l-type.macro';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import Modal from '@haaretz/s-modal';
import React from 'react';
import s9 from 'style9';

const borderRadius = space(4);

const c = s9.create({
  modal: {
    backgroundColor: color('contentBg'),
    border: 'none',
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    height: space(39),
    left: 0,
    marginBottom: 0,
    minWidth: '100%',
    paddingBottom: 0,
    paddingInlineEnd: space(7),
    paddingInlineStart: space(5),
    paddingTop: space(6),
    right: 0,
    width: '100%',
    '::backdrop': {
      '--appPromptBackdropBg': color('rainbowModalMobileBackdrop', { opacity: 0.8 }),
      animationName: s9.keyframes({
        '0%': { backgroundColor: 'transparent' },
        '100%': { backgroundColor: 'var(--appPromptBackdropBg)' },
      }),
      animationDirection: 'normal',
      animationDuration: '0.25s',
      animationFillMode: 'forwards',
      animationPlayState: 'running',
      backgroundColor: 'var(--appPromptBackdropBg)',
      padding: 0,
    },
  },
  onModalOpenKeyFrames: {
    animationName: s9.keyframes({
      '0%': { opacity: 0, transform: 'translateY(120%)' },
      '30%': { opacity: 0 },
      '100%': { opacity: 1, transform: 'translateY(0)' },
    }),
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
  },
  closeClickArea: {
    position: 'absolute',
    insetInlineEnd: 0,
    top: 0,
    height: space(8),
    width: space(8),
  },
  closeIcon: {
    ...typesetter(0),
  },
});

type AppPromotionStripModalProps = {
  children: React.ReactNode;
};

export default function AppPromotionStripModal({ children }: AppPromotionStripModalProps) {
  const [isModalOpen, setIsModalOpen] = React.useState(true);

  return (
    <Modal
      labelledBy="app-prompt-title"
      aria-describedby="app-prompt-title"
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onToggle={setIsModalOpen}
      styleExtend={[c.modal, isModalOpen && c.onModalOpenKeyFrames]}
    >
      <ClickArea styleExtend={[c.closeClickArea]} onClick={() => setIsModalOpen(false)}>
        <Icon icon="close" styleExtend={[c.closeIcon]} />
      </ClickArea>
      {children}
    </Modal>
  );
}
