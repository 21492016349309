'use client';
import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import Button, { ButtonProps, ButtonState } from '@haaretz/s-button';
import * as React from 'react';

import sendMailConfirmation, {
  EmailConfirmationResponse,
} from '../../../utils/sendEmailConfirmation';
import useRainbowBiAction from '../../../utils/useRainbowBiAction';

import type { ActionButtonProps } from './ActionButton';

export default function EmailValidationActionButton({
  actionCodeNumber = 81,
  actionText,
  featureName,
  featureType,
  featureTheme,
  campaignName,
  campaignDetails,
  metaData,
  testGroup,
  onActionComplete,
  styleExtend,
  autofocus,
}: ActionButtonProps<EmailConfirmationResponse>) {
  const user = useUser('cookieValue');

  const [btnState, setBtnState] = React.useState<ButtonState>({
    busyNotice: undefined,
    state: 'auto',
  });

  const doBiAction = useRainbowBiAction({
    campaignDetails: campaignDetails ?? undefined,
    actionCodeNumber,
    campaignName: campaignName ?? undefined,
    featureName: featureName ?? undefined,
    featureType: featureType ?? undefined,
    featureTheme: featureTheme ?? undefined,
    metaData,
    testGroup,
    newsletterEmail: user.userMail,
  });

  const onClick = async () => {
    setBtnState({
      busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }),
      state: 'busy',
    });

    try {
      const data = await sendMailConfirmation(user);

      doBiAction && doBiAction();
      onActionComplete && onActionComplete(data);

      setBtnState({
        busyNotice: undefined,
        state: 'disabled',
      });
    } catch (e) {
      setBtnState({
        busyNotice: undefined,
        state: 'auto',
      });
    }
  };

  const buttonProps: ButtonProps = {
    priority: 'secondary',
    variant: 'brand',
    styleExtend,
    onClick,
    ...btnState,
  };
  if (autofocus) buttonProps.autofocus = 'true';

  return <Button {...buttonProps}>{actionText}</Button>;
}
