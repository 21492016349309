'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import Modal from '@haaretz/s-modal';
import s9 from 'style9';

import { useRainbowSlotActions } from '../../../RainbowSlot';

const c = s9.create({
  modalWrapper: {
    border: 'none',
    width: '100%',
    maxWidth: '100%',
    position: 'fixed',
    marginBottom: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingInlineStart: 0,
    paddingInlineEnd: 0,
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'center',
    backgroundImage: `linear-gradient(to bottom, ${color('neutral100')} 15%, ${color(
      'primary200'
    )})` as string,
    translate: '0 0',
    transitionDuration: '0.25s',
    transitionProperty: 'translate',
    transitionTimingFunction: 'ease-in-out',
    '::backdrop': {
      '--bgc': color('rainbowModalMobileBackdrop', { opacity: fork({ default: 0.8, tm: 0.9 }) }),
      backgroundColor: 'var(--bgc)',
      backdropFilter: 'blur(4px)',
      animationDuration: '0.25s',
      transitionTimingFunction: 'ease-in-out',
    },
  },
  closing: {
    translate: '0 100%',
    '::backdrop': {
      animationName: s9.keyframes({
        '0%': { opacity: 1, visibility: 'visible' },
        '100%': { opacity: 0, visibility: 'hidden' },
      }),
    },
  },
});

export default function ScreenOverlayPaywallWrapper({
  children,
}: React.PropsWithChildren<unknown>) {
  const { closing } = useRainbowSlotActions();

  return (
    <Modal
      labelledBy="screen-overlay-paywall"
      isOpen={true}
      styleExtend={[c.modalWrapper, closing && c.closing]}
      forbidClosing
    >
      {children}
    </Modal>
  );
}
