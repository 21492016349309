import config from '@haaretz/l-config';
import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import { useMutation } from '@tanstack/react-query';

type RainbowToolLogRequestData = {
  contentId: string;
  testGroup?: string | null;
};

type RainbowToolLogEntry = {
  contentId: string;
  lastExposure: string;
  exposureCount: number;
  testGroup?: string;
};

export type RainbowToolLogResponse = {
  'marketing-tools-log': RainbowToolLogEntry[];
};

// url for writing rainbow-tools exposure logs
const STATS_LOGGER_URL = `${config.get('personalization')}/marketing/update-marketing-tools-log`;

async function rainbowToolLogger(variables: RainbowToolLogRequestData) {
  const res = await fetch(
    `${STATS_LOGGER_URL}?${new URLSearchParams(discardNullableValues(variables))}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );

  const logData: RainbowToolLogResponse = await res.json();

  return logData;
}

export default function useRainbowToolLogger() {
  return useMutation<RainbowToolLogResponse, Error, RainbowToolLogRequestData>(rainbowToolLogger);
}
