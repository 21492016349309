'use client';

import usePlatform from '@haaretz/s-atoms/platform';
import * as React from 'react';

import getToolFromServer from '../../utils/getToolFromServer';
import RainbowLogger from '../RainbowLogger';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { StyleExtend } from '@haaretz/s-types';

export interface RainbowToolProps {
  tool: RainbowToolFragment;
  /** event-handler to fire when slot renders a rainbow-tool */
  onToolRendered?: (tool: RainbowToolFragment) => void;
  styleExtend?: StyleExtend;
}

export default function RainbowTool({ tool, onToolRendered, styleExtend }: RainbowToolProps) {
  const [toolJsx, setToolJsx] = React.useState<React.ReactNode | null | undefined>(undefined);
  const platform = usePlatform();
  const [isLoading, startTransition] = React.useTransition();

  React.useEffect(() => {
    if (platform !== 'app' && !isLoading && toolJsx === undefined) {
      startTransition(async () => {
        try {
          const toolFromServer = await getToolFromServer({
            tool,
            styleExtend,
            platform,
            postCacheParams: ['sa-rainbow', tool.toolId],
          });
          const jsxFromServer = await toolFromServer;
          return setToolJsx(jsxFromServer);
        } catch (error) {
          console.error(error);
          return setToolJsx(null);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, styleExtend, tool.toolId]);

  React.useEffect(() => {
    if (toolJsx && !isLoading && onToolRendered) {
      onToolRendered(tool);
    }
  }, [toolJsx, onToolRendered, isLoading, tool]);

  return platform === 'app' || isLoading || !toolJsx ? null : (
    <RainbowLogger key={tool.toolId} toolId={tool.toolId} testGroup={tool.testGroup}>
      {toolJsx}
    </RainbowLogger>
  );
}
