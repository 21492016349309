import * as React from 'react';

import useRainbowToolLogger from '../../facts/useRainbowToolStatLogger';

type PropsType = {
  toolId: string;
  testGroup?: string | null;
  children: React.ReactNode;
};

export default function RainbowLogger({ toolId, testGroup, children }: PropsType) {
  const { mutate } = useRainbowToolLogger();
  // Prevent multiple calling the logger mutation
  const effectRan = React.useRef(false);

  // log exposure date of marketing-element on render.`
  React.useEffect(() => {
    if (!effectRan.current) {
      mutate({ contentId: toolId, testGroup });
      effectRan.current = true;
    }
  }, [toolId, testGroup, mutate, effectRan]);

  return children;
}
