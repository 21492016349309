'use client';
import color from '@haaretz/l-color.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import ErrorBoundary from '@haaretz/s-error-boundary';
import LogoLoadingIndicator from '@haaretz/s-logo-loading-indicator';
import * as React from 'react';
import s9 from 'style9';

import useIsRainbowEnabled from '../../utils/useIsRainbowEnabled/useIsRainbowEnabled';

import RainbowSlot from './RainbowSlot';

import type { RainbowSlotProps } from './RainbowSlot';
import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { StyleExtend } from '@haaretz/s-types';

const c = s9.create({
  base: {
    gridColumnStart: 'main-start',
    gridColumnEnd: 'main-end',
    justifyContent: 'center',
    zIndex: zIndex('drawer'),
    position: 'relative',
  },
  gradient: {
    '--gradientWidth': space(26),
    position: 'relative',
    marginTop: 'calc(-1 * var(--gradientWidth))',
    backgroundImage: `linear-gradient(to bottom,
      ${color('contentBg', { opacity: 0 })} 0%,
      ${color('contentBg')} var(--gradientWidth),
      ${color('contentBg')} 100%)` as string,
    ...merge({
      ...mq({
        from: 's',
        value: {
          '--gradientWidth': space(24),
        },
      }),
      ...mq({
        from: 'xl',
        value: {
          '--gradientWidth': space(37),
        },
      }),
    }),
  },

  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: space(20),
    height: space(108),
    ...merge({
      ...mq({
        from: 's',
        value: { height: space(124) },
      }),
      ...mq({
        from: 'm',
        value: { height: space(120) },
      }),
      ...mq({
        from: 'l',
        value: { height: space(116) },
      }),
      ...mq({
        from: 'xxl',
        value: { height: space(144) },
      }),
    }),
  },
});

function RainbowPaywallSlot({ id, timeout, onToolRendered }: RainbowSlotProps) {
  const isRainbowEnabled = useIsRainbowEnabled();
  const renderingKind = useRenderingKind();
  const isArticleBlocked = renderingKind === 'blocked';

  const [showLoader, setShowLoader] = React.useState(true);

  const onPaywallToolRendered = React.useCallback(
    (tool: RainbowToolFragment) => {
      if (tool) {
        setShowLoader(false);
      }

      onToolRendered && onToolRendered(tool);
    },
    [onToolRendered]
  );

  if (!isArticleBlocked || !isRainbowEnabled) {
    return null;
  }

  return (
    <MidpageWrapper showLoader={showLoader} styleExtend={[c.base]}>
      <RainbowSlot
        id={id}
        onToolRendered={onPaywallToolRendered}
        timeout={timeout}
        styleExtend={[c.base]}
      />
    </MidpageWrapper>
  );
}

interface MidpageWrapperProps {
  showLoader: boolean;
  styleExtend?: StyleExtend;
  children: React.ReactNode;
}

function MidpageWrapper({ showLoader, styleExtend = [], children }: MidpageWrapperProps) {
  return (
    <div className={s9(showLoader && c.gradient, ...styleExtend)}>
      {showLoader ? (
        <div className={s9(c.loader)}>
          <LogoLoadingIndicator />
        </div>
      ) : null}

      {children}
    </div>
  );
}

export default function RainbowPaywallSlotWrapper(props: RainbowSlotProps) {
  return (
    <ErrorBoundary>
      <RainbowPaywallSlot {...props} />
    </ErrorBoundary>
  );
}
