'use client';

import Script from 'next/script';

import type { RenderingKind } from '@haaretz/s-types';

interface WrapGlobalFetchProps {
  renderingKind: RenderingKind;
}

export default function WrapGlobalFetch({ renderingKind }: WrapGlobalFetchProps) {
  return (
    <Script id="global-interceptors" strategy="beforeInteractive">{`
    const { fetch: originalFetch } = window;

    window.fetch = async (...args) => {
      let [resource, config] = args;

      const isServerActionRequest = resource === '' &&
        !!config &&
        config?.method === 'POST' &&
        typeof config?.headers === 'object' &&
        config.headers?.Accept === 'text/x-component';

      if (isServerActionRequest && typeof config.body === 'string') {
        try {
          const bodyJSON = JSON.parse(config.body || "[]")?.[0] || {};

          // Dev mode only validations
          if('${process.env.NODE_ENV}' === 'development') {
            if (!bodyJSON.postCacheParams) {
              throw new Error(\`Invalid server action request: postCacheParams, body: \${JSON.stringify(bodyJSON, null, 2)}\`);
            }
          }

          const {postCacheParams, ...body} = bodyJSON;

          const payloadHash = await htzSha256(JSON.stringify(body));

          config.body = JSON.stringify([body]);

          const newResourceUrl = new URL(resource, window.location.href);

          newResourceUrl.searchParams.set('post-cache-params', Array.isArray(postCacheParams) ? postCacheParams.filter(Boolean).join(' ') : postCacheParams);
          newResourceUrl.searchParams.set('payload-hash', payloadHash);

          ${
            renderingKind === 'proxy'
              ? "newResourceUrl.searchParams.set('Next-Action', config.headers['Next-Action']);"
              : ''
          }

          resource = newResourceUrl.toString();
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      }

      return originalFetch(resource, config);
    };
    `}</Script>
  );
}
