'use client';

import useBi from '@haaretz/s-use-bi';
import { useMemo } from 'react';

import useRainbowCommonBiData from './useRainbowCommonBiData';

import type { RainbowActionBiData, BiDataOverrides } from '@haaretz/s-data-structure-types';

export default function useRainbowBiAction({
  actionCodeNumber,
  featureName,
  featureType,
  featureTheme,
  campaignName,
  campaignDetails,
  metaData,
  testGroup,
}: RainbowActionBiData) {
  const biAction = useBi();

  const commonBiData = useRainbowCommonBiData({
    featureName,
    featureType,
    featureTheme,
    campaignName,
    campaignDetails,
    metaData,
    testGroup,
  });

  const actionFunc = useMemo(() => {
    if (!actionCodeNumber) {
      return null;
    }

    return (overrideBiData?: BiDataOverrides) => {
      biAction({
        action_id: actionCodeNumber,
        ...commonBiData,
        ...(overrideBiData || {}),
      });
    };
  }, [actionCodeNumber, biAction, commonBiData]);

  return actionFunc;
}
