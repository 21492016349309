import config from '@haaretz/l-config';
import fork from '@haaretz/l-fork.macro';

import type { HtzUser } from '@haaretz/s-types';

export interface EmailConfirmationResponse {
  success: boolean;
  message: string | null;
}

export default async function sendMailConfirmation(user: HtzUser) {
  const site = fork({ default: 'htz', htz: 'htz', tm: 'themarker', hdc: 'ENG' });
  const paramString = window.btoa(JSON.stringify({ email: user.userMail }));
  const confirmationType = fork({
    default: 'HTZ_MAIL_VALIDATION',
    tm: 'THEMARKER_MAIL_VALIDATION',
    hdc: 'ENG_MAIL_VALIDATION',
  });

  const body = {
    confirmationParams: { email: user.userMail },
    confirmationType,
    templateParams: {
      url: `${config.get(
        'msConfirmation'
      )}/validateAndRedirect?site=${site}&confirmationType=${confirmationType}&idType=SSO_ID`,
      paramsString: `params=${paramString}&type=EMAIL_VALIDATION`,
      firstName: user.firstName,
    },
  };

  const res = await fetch(`${config.get('functionService')}/sendEmailForConfirmation`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  const data: EmailConfirmationResponse = await res.json();

  return data;
}
