import * as React from 'react';

export default function useBrowserDetect() {
  const [browser, setBrowser] = React.useState<'safari' | 'chrome' | 'other'>();

  React.useEffect(() => {
    const { userAgent } = navigator;

    if (/\b(Chrome|CriOS)\b/i.test(userAgent) && !/\b(Chromium|Edg)\b/i.test(userAgent)) {
      setBrowser('chrome');
    } else if (/\b(Safari)\b/i.test(userAgent) && !/\b(Chrome|Chromium)\b/i.test(userAgent)) {
      setBrowser('safari');
    } else {
      setBrowser('other');
    }
  }, [setBrowser]);

  return browser;
}
