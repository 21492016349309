'use client';
import fork from '@haaretz/l-fork.macro';
import Button from '@haaretz/s-button';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import * as React from 'react';

import { useRainbowSlotActions } from '../../../components/RainbowSlot';
import isPromotionUrl from '../../../utils/isPromotionUrl';
import useRainbowBiAction from '../../../utils/useRainbowBiAction';

import type { ButtonProps, ButtonState } from '@haaretz/s-button';
import type { RainbowActionBiData } from '@haaretz/s-data-structure-types';
import type { MultiTypeMarketingElementFragment } from '@haaretz/s-fragments/MultiTypeMarketingElement';
import type { StyleExtend } from '@haaretz/s-types';

export type ActionButtonProps<T = unknown> = RainbowActionBiData & {
  smallOnMobile?: boolean;
  closeOnClick?: boolean;
  variant?: ButtonProps['variant'];
  priority?: ButtonProps['priority'];
  size?: ButtonProps['size'];
  actionText: MultiTypeMarketingElementFragment['actionText'];
  actionUrl: MultiTypeMarketingElementFragment['actionUrl'];
  actionType: MultiTypeMarketingElementFragment['actionType'];
  styleExtend?: StyleExtend;
  onActionComplete?: (data?: T) => void;
} & ({ autofocus: true } | { autofocus?: never });

export default function ActionButton({
  smallOnMobile,
  variant,
  priority = 'primary',
  actionCodeNumber,
  actionText,
  actionType = 'navigation',
  actionUrl,
  autofocus,
  campaignDetails,
  campaignName,
  featureName,
  featureType,
  featureTheme,
  metaData,
  closeOnClick,
  size,
  /* callback for 'servicecall' action types, when service call completed */
  onActionComplete,
  styleExtend,
  testGroup,
}: ActionButtonProps) {
  const [btnState, setBtnState] = React.useState<ButtonState>({
    busyNotice: undefined,
    state: 'auto',
  });

  const breakpoint = useBreakpoint();
  const { onClose } = useRainbowSlotActions();

  const isPromotion = !!actionUrl && isPromotionUrl(actionUrl);
  const btnVariant: ButtonProps['variant'] = variant || (isPromotion ? 'sales' : 'brand');

  const doBiAction = useRainbowBiAction({
    campaignDetails: campaignDetails ?? undefined,
    actionCodeNumber,
    campaignName: campaignName ?? undefined,
    featureName: featureName ?? undefined,
    featureType: featureType ?? undefined,
    featureTheme: featureTheme ?? undefined,
    metaData,
    testGroup,
  });

  let onClick = null;

  if (actionType === 'servicecall') {
    onClick = async () => {
      setBtnState({
        busyNotice: fork({ default: 'טוען...', hdc: 'loading...' }),
        state: 'busy',
      });

      if (actionUrl) {
        const res = await fetch(actionUrl, { method: 'GET' });
        const data = await res.json();

        doBiAction && doBiAction();
        onActionComplete && onActionComplete(data);
      }
      setBtnState({
        busyNotice: undefined,
        state: 'disabled',
      });
      closeOnClick && onClose();
    };
  } else {
    onClick = () => {
      doBiAction && doBiAction();
      closeOnClick && onClose();
    };
  }

  const buttonProps: ButtonProps = {
    priority,
    variant: btnVariant,
    styleExtend,
    onClick,
    size,
    ...btnState,
  };

  if (smallOnMobile && breakpoint === 'default') {
    buttonProps.size = 'small';
  }
  if (autofocus) buttonProps.autofocus = 'true';

  const as: { as?: 'a'; href?: string; 'data-google-interstitial'?: boolean } =
    actionType === 'navigation' && actionUrl
      ? { as: 'a', href: actionUrl, 'data-google-interstitial': false }
      : {};

  return (
    <Button {...as} {...buttonProps}>
      {actionText}
    </Button>
  );
}
