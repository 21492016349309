'use client';

import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import Button from '@haaretz/s-button';
import * as React from 'react';
import s9 from 'style9';

const c = s9.create({
  expandBtn: {
    marginInlineStart: space(4),
    alignSelf: 'start',
    flexShrink: '0',
  },
  mobileExclude: {
    ...mq({ until: 's', value: { display: 'none' } }),
  },
  visuallyHidden: {
    border: '0',
    // @ts-expect-error - for old browsers
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    margin: '0 -1px -1px 0',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
    whiteSpace: 'nowrap',
    wordWrap: 'normal',
    opacity: 0,
  },
  mainNavAndBtnWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: space(4),
  },
});

export default function NavAndBtnWrapper({
  nav,
  children,
}: {
  nav: React.ReactNode;
  children: React.ReactNode;
}) {
  const renderingKind = useRenderingKind();
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(renderingKind !== 'expanded');

  function toggleIsCollapsed() {
    setIsCollapsed(!isCollapsed);
  }

  return (
    <>
      <div className={s9(c.mainNavAndBtnWrapper)}>
        {nav}
        <Button styleExtend={[c.expandBtn, c.mobileExclude]} onClick={toggleIsCollapsed}>
          {!isCollapsed
            ? fork({ default: 'סגור', hdc: 'close' })
            : fork({ default: 'הצג עוד', hdc: 'Load More' })}
        </Button>
      </div>
      <div className={s9(isCollapsed && c.visuallyHidden)} data-testid="expandedList">
        {children}
      </div>
    </>
  );
}
