'use server';

import rainbowToolComponentResolver from '../rainbowToolComponentResolver';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { PlatformType, BaseServerActionParams, StyleExtend } from '@haaretz/s-types';

interface ToolFromServerType extends BaseServerActionParams {
  tool: RainbowToolFragment;
  platform: PlatformType;
  styleExtend?: StyleExtend;
}

const defaultResponse = null;

export default async function getToolFromServer({
  tool,
  platform,
  styleExtend,
}: ToolFromServerType) {
  try {
    const resolvedTool = rainbowToolComponentResolver(tool.element, {
      platform,
      metaData: tool?.metaData,
      testGroup: tool?.testGroup,
      styleExtend,
    });

    return resolvedTool;
  } catch (error) {
    console.error(`Rainbow server action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
