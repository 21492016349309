'use client';

import space from '@haaretz/l-space.macro';
import Icon from '@haaretz/s-icon';
import useBrowserDetect from '@haaretz/s-use-browser-detect';
import s9 from 'style9';

const c = s9.create({
  browserIcon: {
    height: space(5),
    width: space(5),
    margin: 0,
    padding: 0,
    overflow: 'hidden',
  },
  otherBrowserIcon: {
    height: space(6),
    width: space(6),
  },
});

type BrowserIcons = {
  chrome: 'chrome';
  safari: 'safari';
  other: 'browser';
};

const browserMap: BrowserIcons = {
  chrome: 'chrome',
  safari: 'safari',
  other: 'browser',
} as const;

function getBrowserIcon(browser: keyof BrowserIcons | undefined) {
  return browserMap[browser ?? 'other'];
}

export default function BrowserIcon() {
  const browser = useBrowserDetect();
  const iconName = getBrowserIcon(browser);

  return (
    <Icon
      icon={iconName}
      styleExtend={[browser === 'other' ? c.otherBrowserIcon : c.browserIcon]}
    />
  );
}
